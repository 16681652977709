import {
  Alert,
  Autocomplete,
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useMutation } from "react-query";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CustomTextField from "components/Form/CustomTextField";
import FormErrors from "components/Form/FormErrors";
import useDropdowns from "hooks/useDropdowns";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useAppContextController } from "context/AppContext";
import { useNewApplicantContext } from "context/NewApplicantContext";
import { getDirtyFields } from "utils/helpers/formHelpers";
import MDBox from "components/MDBox";
import TagList from "components/TagList";
import updateApplicant from "layouts/pages/applicants/actions/updateApplicant";
import { baseAxios } from "config";
import FileDropzone from "components/Dropzone";
import DataListContainer from "components/DataListContainer";
import JobHistoryItem from "../JobHistoryItem";
import JobHistoryModal from "../JobHistoryModal";
import EducationItem from "../EducationItem";
import EducationModal from "../EducationModal";

const ApplicantResumeAndJobHistory = () => {
  const {
    applicant,
    updateApplicantAction,
    updateButtons,
    buttonState,
    updateCurrentFormState,
    currentFormState,
  } = useNewApplicantContext();
  const { user } = useAppContextController();

  const { companyType, userType, currentLoggedUser, company } = useAppContextController();
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });
  const [availability, setAvailability] = useState(applicant?.availability || "");
  const IMAGE_SERVER = company?.imageUrl;
  const [resumeExtension, setResumeExtension] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [fileInfo, setFileInfo] = useState(null);
  const [enterDateSelected, setEnterDateSelected] = useState(false);
  const isCompany = companyType === "Company";

  const defaultValues = {
    criminalHistoryDisclosure: applicant?.criminalHistoryDisclosure || "",
    jobHistory: applicant?.jobHistory || [],
    education: applicant?.education || [],
    tags: applicant?.tags || [],
  };

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    trigger,
    formState: { errors, isDirty, isSubmitSuccessful, isValid, dirtyFields },
  } = useForm();

  const [isJobHistoryModalOpen, setJobHistoryModalOpen] = useState(false);
  const [currentJobHistory, setJobHistory] = useState({});
  const [jobHistoryIndex, setJobHistoryIndex] = useState(null);
  const [isEducationModalOpen, setEducationModalOpen] = useState(false);
  const [currentEducation, setEducation] = useState({});
  const [educationIndex, setEducationIndex] = useState(null);

  const { dropdowns: criminalRecords } = useDropdowns({
    entity: "criminalRecord",
    outsideMode: user ? "" : "protected",
    enabled: isCompany,
  });

  const onSubmit = async (data) => {
    const parsedData = getDirtyFields(data, dirtyFields);

    updateApplicantAction(applicant._id, parsedData);

    setTimeout(
      () =>
        document.getElementById("next-btn").scrollIntoView({ block: "center", inline: "center" }),
      1000
    );
  };

  const updateApplicantMutation = useMutation(updateApplicant, {
    onError: () =>
      setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" }),
  });

  const uploadFileMutation = useMutation(
    async (resumeForm) => {
      await baseAxios.post(
        `/outside-protected/upload/applicants/${applicant?._id}/Resume`,
        resumeForm.form
      );

      const newAtt = {
        title: "Resume",
        type: "Resume",
        docType: resumeForm.extension,
        filename: resumeForm.name,
        uploadDate: new Date(),
      };
      // if we find this attachment type then overwrite the current
      const newAttachmentsArr = [newAtt];

      await updateApplicantMutation.mutateAsync(
        {
          applicantId: applicant?._id,
          data: {
            attachments: [...newAttachmentsArr],
          },
          outsideMode: "protected",
        },
        {
          onSuccess: (_, data) => {
            setToastAlert({
              isShow: true,
              message: "Resume Has Been Uploaded!",
              status: "success",
            });
          },
        }
      );
    },
    {
      onSuccess: (_, data) => {
        // setValue(
        //   "resumeUrl",
        //   `${IMAGE_SERVER}/resumes/${job.jobSlug}/${getValues().email}/${data?.name}`
        // );
        setResumeExtension(data?.extension);
        setFileName(data?.name);
        setToastAlert({ isShow: true, message: "Resume Has Been Uploaded!", status: "success" });
      },
    }
  );

  const onDropHandler = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length !== 0) {
      setToastAlert({ isShow: true, message: rejectedFiles[0].errors[0].message, status: "error" });
    }
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      const form = new FormData();
      form.append("file", file);
      uploadFileMutation.mutate({
        form,
        type: "Resume",
        name: file?.name,
        extension: file?.path?.split(".").pop(),
      });
    }
  };

  const handleJobHistory = (idx, row, isOpen) => {
    setJobHistoryIndex(idx);
    setJobHistory(row || {});
    setJobHistoryModalOpen(isOpen);
  };
  const handleEducation = (idx, row, isOpen) => {
    setEducationIndex(idx);
    setEducation(row || {});
    setEducationModalOpen(isOpen);
  };

  const handleJobHistoryChange = (jobHistory) => {
    setValue("jobHistory", jobHistory, { shouldDirty: true });
  };
  const handleEducationChange = (education) => {
    setValue("education", education, { shouldDirty: true });
  };

  const handleRemoveJobHistory = (idx) => {
    const newJobHistoryList = watch("jobHistory");
    const updatedJobHistory = newJobHistoryList.filter((_, index) => index !== idx);
    setValue("jobHistory", updatedJobHistory, { shouldDirty: true });
    setJobHistoryModalOpen(false);
  };
  const handleRemoveEducation = (idx) => {
    const newEducationList = watch("education");
    const updatedEducation = newEducationList.filter((_, index) => index !== idx);
    setValue("education", updatedEducation);
    setEducationModalOpen(false);
  };

  const renderJobHistory = (row, idx) => (
    <JobHistoryItem
      row={row}
      idx={idx}
      onEdit={handleJobHistory}
      onRemove={handleRemoveJobHistory}
    />
  );
  const renderEducation = (row, idx) => (
    <EducationItem row={row} idx={idx} onEdit={handleEducation} onRemove={handleRemoveEducation} />
  );

  const handleAvailabilityChange = (e) => {
    const currentDate = new Date();

    setAvailability(e.target.value);
    if (e.target.value === "enterDate") {
      setEnterDateSelected(true);
      setValue("availability", null, { shouldDirty: true });
    } else {
      setEnterDateSelected(false);
    }
    if (e.target.value === "Immediate") {
      setValue("availability", currentDate.toISOString(), { shouldDirty: true });
    }
    if (e.target.value === "WithinTwoWeeks") {
      const newDate = new Date(currentDate);
      newDate.setDate(currentDate.getDate() + 15);
      setValue("availability", newDate.toISOString(), { shouldDirty: true });
    }
    if (e.target.value === "Within30Days") {
      const newDate = new Date(currentDate);
      newDate.setDate(currentDate.getDate() + 30);
      setValue("availability", newDate.toISOString(), { shouldDirty: true });
    }
  };

  useEffect(() => {
    if (applicant) {
      const resume = applicant?.attachments?.find((attachment) => attachment.type === "Resume");
      if (resume) {
        setResumeExtension(resume?.docType);
      }

      reset(defaultValues, { keepErrors: true });
    }
  }, [applicant?._id]);

  useEffect(() => {
    updateCurrentFormState({
      ...currentFormState,
      isDirty,
    });

    updateButtons({
      ...buttonState,
      submit: {
        show: true,
        disabled: !isDirty,
      },
    });
  }, [isDirty]);

  useEffect(() => {
    updateButtons({
      ...buttonState,
      previous: {
        show: true,
        disabled: false,
      },
      next: {
        show: true,
        disabled: false,
      },
      submit: {
        show: true,
        disabled: false,
      },
    });
  }, []);

  useEffect(() => {
    if (isValid) {
      updateButtons({
        ...buttonState,
        next: {
          show: true,
          disabled: false,
        },
      });
    }
  }, [isValid]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      updateButtons({
        ...buttonState,
        next: {
          show: true,
          disabled: false,
        },
        submit: {
          show: true,
          disabled: false,
        },
      });
    }
  }, [isSubmitSuccessful]);

  useEffect(() => {
    reset(defaultValues);
  }, [applicant]);

  const avatar = (
    <MDBox>
      {IMAGE_SERVER &&
      (resumeExtension?.toLowerCase() === "docx" || resumeExtension?.toLowerCase() === "rtf") ? (
        <img
          src={`${IMAGE_SERVER}/static/word-icon.png`}
          alt="Word Doc Preview"
          width={100}
          height={100}
        />
      ) : (
        resumeExtension?.toLowerCase() === "pdf" &&
        IMAGE_SERVER && (
          <img
            src={`${IMAGE_SERVER}/static/pdf-icon.png`}
            alt="PDF Preview"
            width={100}
            height={100}
          />
        )
      )}
    </MDBox>
  );

  return (
    <>
      <Snackbar
        open={toastAlert.isShow}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
        key="top-center"
      >
        <Alert
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          severity={toastAlert.status}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastAlert.message}
        </Alert>
      </Snackbar>
      <form onSubmit={handleSubmit(onSubmit)} id="current-form">
        <FlexWrapper sx={{ flexDirection: "column", rowGap: "16px" }}>
          <>
            <Typography variant="h6">Resume upload (optional) </Typography>
            <Box px={3} sx={{ width: "100%" }}>
              <FileDropzone
                accept={{
                  "application/docx": [".docx"],
                  "application/pdf": [".pdf"],
                  "application/rtf": [".rtf"],
                }}
                message="Drop files here to upload"
                onDrop={(acceptedFiles, rejectedFiles) =>
                  onDropHandler(acceptedFiles, rejectedFiles)
                }
                avatar={resumeExtension && avatar}
              />
            </Box>

            <Box px={3} sx={{ width: "100%" }}>
              <Typography variant="h6" textAlign="start">
                Availability{" "}
              </Typography>
            </Box>
            <Box px={3} sx={{ width: "100%" }}>
              <RadioGroup
                aria-labelledby={`availability_${applicant?._id}`}
                name="availability"
                value={availability}
                onChange={handleAvailabilityChange}
              >
                <Grid container>
                  <Grid item xs={12} sm={3}>
                    <Stack display="flex" direction="row" alignItems="center">
                      <FormControlLabel
                        value="Immediate"
                        control={<Radio />}
                        sx={{ marginRight: "0" }}
                      />
                      <Typography fontWeight="bold" fontSize="0.875rem">
                        Immediate
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Stack display="flex" direction="row" alignItems="center">
                      <FormControlLabel
                        value="WithinTwoWeeks"
                        control={<Radio />}
                        sx={{ marginRight: "0" }}
                      />
                      <Typography fontWeight="bold" fontSize="0.875rem">
                        Within the next two weeks
                      </Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <Stack display="flex" direction="row" alignItems="center">
                      <FormControlLabel
                        value="Within30Days"
                        control={<Radio />}
                        sx={{ marginRight: "0" }}
                      />
                      <Typography fontWeight="bold" fontSize="0.875rem">
                        Within the next 30 days
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Stack display="flex" direction="row" alignItems="center">
                      <FormControlLabel
                        value="enterDate"
                        control={<Radio />}
                        sx={{ marginRight: "0" }}
                      />
                      <Typography fontWeight="bold" fontSize="0.875rem">
                        Enter Date
                      </Typography>
                      <Controller
                        name="enterDate"
                        control={control}
                        disabled={!enterDateSelected}
                        defaultValue={null}
                        render={({ field, fieldState: { error } }) => (
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                              {...field}
                              label="Enter Date"
                              renderInput={(params) => (
                                <CustomTextField variant="outlined" noErrorIcon {...params} />
                              )}
                              onChange={(...props) => {
                                const [value, textValue] = props;
                                if (value && value.isValid && value.isValid()) {
                                  setValue("availability", value.toISOString(), {
                                    shouldDirty: true,
                                  });
                                } else {
                                  setValue("availability", null, { shouldDirty: true });
                                }
                                field.onChange(...props);
                              }}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </RadioGroup>
            </Box>
            <Box px={3} sx={{ width: "100%" }}>
              <DataListContainer
                onAdd={handleJobHistory}
                data={watch("jobHistory") || []}
                renderRow={renderJobHistory}
                title="Job History"
                subtext="(Please complete this section if you did not attach a resume)"
              />
              <DataListContainer
                onAdd={handleEducation}
                data={watch("education") || []}
                renderRow={renderEducation}
                title="Education"
              />
            </Box>
            <MDBox mt={3} px={3} width="100%">
              <Stack display="flex" direction="row" alignItems="center">
                <Typography variant="h5">Skills &nbsp;</Typography>
                <Typography variant="body2">
                  (entering skills allow us to match you to more opportunities)
                </Typography>
              </Stack>
              <Controller
                name="tags"
                control={control}
                render={({ field }) => (
                  <TagList
                    {...field}
                    value={watch("tags") || []}
                    setValue={setValue}
                    setError={setError}
                    clearErrors={clearErrors}
                    currentComponent={applicant}
                    outsideMode={user ? "" : "protected"}
                    label="Skills from Master List"
                  />
                )}
              />
              {errors?.invalidTag && (
                <Typography color="error">{errors?.invalidTag.message}</Typography>
              )}
            </MDBox>
          </>
        </FlexWrapper>
        <FormErrors errors={errors} />
      </form>
      {isJobHistoryModalOpen && (
        <JobHistoryModal
          jobHistoryList={watch("jobHistory")}
          currentJobHistory={currentJobHistory}
          setJobHistory={setJobHistory}
          jobHistoryIndex={jobHistoryIndex}
          setJobHistoryIndex={setJobHistoryIndex}
          onJobHistoryChange={handleJobHistoryChange}
          isModalOpen={isJobHistoryModalOpen}
          setModalOpen={setJobHistoryModalOpen}
        />
      )}
      {isEducationModalOpen && (
        <EducationModal
          educationList={watch("education")}
          currentEducation={currentEducation}
          setEducation={setEducation}
          educationIndex={educationIndex}
          setEducationIndex={setEducationIndex}
          onEducationChange={handleEducationChange}
          isModalOpen={isEducationModalOpen}
          setModalOpen={setEducationModalOpen}
        />
      )}
    </>
  );
};

export default ApplicantResumeAndJobHistory;
